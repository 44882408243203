<template>
	<div class="page">
		<div class="banner" v-if="bannerList.length>0">
			<div id="demo" class="carousel slide" data-ride="carousel">
				<!-- 轮播图片 -->
				<div class="carousel-inner">
					<div class="carousel-item pointer" :class="[index == 0 ? 'active' : '']" v-for="(item,index) in bannerList" :key="index">
						<img style="width: 100%;height:auto;" @click="$util.windowOpen(item.redirectUrl)" class="img" :src="item.imageUrl" />
					</div>
				</div>
			</div>
		</div>
		<!-- 菜单栏目 -->
		<topBar></topBar>
		<!-- 轮播图 -->
		<div class="w1240 xc_banner_box relative">
			<div class="typelist_box">
				<div class="itemobj">
					<div class="item" v-for="(item,index) in menuList" :key="index" @click="handleMenu(index)">
						<div class="text">{{item.title}}</div>
						<div class="mixicon iconfont icon-you"></div>
					</div>
				</div>
			</div>
			<div class="xc_login_box" :style="'right:'+loginright">
				<div class="userinfo"></div>
				<div class="user_out">
					<div class="name" v-if="!isLogin">欢迎来到{{bsnName}}</div>
					<div class="btnlist" v-if="!isLogin">
						<div class="btn1" @click="handlezhuceiForm('open')">注册</div>
						<div class="btn2" @click="handleLoginForm('open')">登录</div>
					</div>
					<img v-if="isLogin" @click="tobackMine" class="user_avatar" :src="avatarUrl || require('../assets/static/logo.png')"/>
					<div v-if="isLogin" class="username clamp">{{nickName || bsnName}}</div>
				</div>
				<div class="line"></div>
				<div class="tipslist">
					<div class="item">
						<img src="../assets/static/Frame2.png"/>
						<div class="text">正品保证</div>
					</div>
					<div class="item">
						<img src="../assets/static/Frame4.png"/>
						<div class="text">厂家授权</div>
					</div>
					<div class="item">
						<img src="../assets/static/Frame1.png"/>
						<div class="text">货到付款</div>
					</div>
					<div class="item">
						<img src="../assets/static/Frame3.png"/>
						<div class="text">退货无忧</div>
					</div>
					<div class="item">
						<img src="../assets/static/Frame5.png"/>
						<div class="text">安全配送</div>
					</div>
					<div class="item">
						<img src="../assets/static/Frame6.png"/>
						<div class="text">多种付款</div>
					</div>
				</div>
				<div class="contact_box">客服xxx-xxx-7272</div>
			</div>
			<div></div>
			<div class="bannerlist_box">
				<div class="swiper relative">
				    <swiper ref="mySwiper1" :options="swiperOptions1">
				        <swiper-slide
				            class="swiperslide"
				            v-for="(item,index) in bannerList1"
				            :key="index">
							<img class="img pointer" :src="item.imageUrl" @click="$util.windowOpen(item.redirectUrl)"/>
				        </swiper-slide>
				    </swiper>
				</div>
			</div>
		</div>
		<!-- 系统公告 -->
		<div class="w1240 sys_notice relative">
			<div class="img">
				<img src="../assets/static/Group18.png" />
			</div>
			<div class="line"></div>
			<Marquee class="realData">
			    <ul class="fa-scroll-cont">
			        <li class="fa-scroll-li pointer" v-for="(item,index) in noticeList" :key="index" @click="clickarticleDetail(item.id)">
			            <span class="roll-text">{{ item.title }}</span>
			        </li>
			    </ul>
			</Marquee>
		</div>
		<div class="block20"></div>
		<!-- 今日特价 -->
		<div class="w1240 xc_tejia_box">
			<div class="imglogo">
				<img src="../assets/static/Group2214.png"/>
			</div>
			<div class="list_box relative">
				<div class="btn_prev" @click="slidePrev"><span class="iconfont icon-you"></span></div>
				<div class="btn_next" @click="slideNext"><span class="iconfont icon-you"></span></div>
				<div>
					<swiper ref="mySwiper" :options="swiperOptions">
					    <swiper-slide v-for="(item,index) in tejiaList" :key="index">
					    	<div class="tejia_item pointer" @click="clickgoodsDetail(item.id,'tejia')">
								<div class="img">
									<img :src="item.medicineDrugs.mainImages[0] || require('../assets/static/logo.png')"/>
								</div>
					    		<div class="tejia_con">
					    			<div class="title clamp2">{{item.goodsName}}</div>
									<div class="title"><span class=" badge badge-info">{{item.supervision_class}}</span></div>
					    			<div class="desc" v-if="isLogin">促销价：￥{{item.finalPrice}}</div>
									<div class="desc" v-if="!isLogin">促销价：会员登录可见</div>
					    		</div>
					    	</div>
					    </swiper-slide>
					</swiper>
				</div>
			</div>
		</div>
		<div class="block20" v-if="swiperList1.length>0"></div>
		<!-- 广告位1 -->
		<div class="xc_center_banner w1240" v-if="swiperList1.length>0">
			<el-carousel
				class="swiper"
				indicator-position="none"
				arrow="never">
				<el-carousel-item class="item" v-for="(item, index) in swiperList1" :key="index" >
					<img 
						class="pic pointer"
						:src="item.imageUrl"
						@click="$util.windowOpen(item.redirectUrl)"
					/>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="block20"></div>
		<!-- 品牌专区 -->
		<div class="xc_brand_box">
			<div class="type_box">
				<div class="line"></div>
				<div class="text">品牌专区</div>
			</div>
			<div class="list_box">
				<div class="item pointer" v-for="(item,index) in brandList" :key="index" @click="handlebrandDetail(item.id)">
					<img :src="item.imageUrl" />
				</div>
			</div>
		</div>
		<div class="block20" v-if="actList.length>0"></div>
		<!-- 活动专区 -->
		<div class="xc_brand_box" v-if="actList.length>0">
			<div class="type_box">
				<div class="line" style="background-color: #3E9149;"></div>
				<div class="text">活动专区</div>
			</div>
			<div class="list_box">
				<div class="item pointer" v-for="(item,index) in actList" :key="index" @click="$util.windowOpen(item.redirectUrl)">
					<img :src="item.imageUrl" />
				</div>
			</div>
		</div>
		<!-- 广告位2 -->
		<div class="block20" v-if="swiperList2.length>0"></div>
		<div class="xc_center_banner w1240" v-if="swiperList2.length>0">
			<el-carousel
				class="swiper"
				indicator-position="none"
				arrow="never">
				<el-carousel-item class="item" v-for="(item, index) in swiperList2" :key="index" >
					<img 
						class="pic pointer"
						:src="item.imageUrl"
						@click="$util.windowOpen(item.redirectUrl)"
					/>
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 新片上市等活动栏目 -->
		<div class="w100" v-for="(item, index) in actMenu" :key="index">
			<div class="block20" v-if="item.goodsList&&item.goodsList.length>0"></div>
			<div class="xc_goodslist_box" v-if="item.goodsList&&item.goodsList.length>0">
				<div class="type_box">
					<div class="line"></div>
					<div class="text">{{item.title}}</div>
				</div>
				<div class="datalist">
					<div class="item pointer" v-for="(gitem, gindex) in item.goodsList" :key="gindex" @click="clickgoodsDetail(gitem.id,'goods')">
						<div class="img"><img :src="gitem.medicineDrugs.mainImages[0]" /></div>
						<div class="conbox">
							<div class="title clamp2">{{gitem.goodsName}}</div>
							<div class="block20"></div>
							<div class="pricebox">
								<div class="nowprice" v-if="isLogin">¥{{gitem.finalPrice}}</div>
								<div class="nowprice" v-if="!isLogin">¥登录查价</div>
								<div class="oldprice" v-if="isLogin">¥{{gitem.oriPrice}}</div>
								<div class="volume">月销{{gitem.salesVolume}}笔</div>
								<div class="volume"><span class=" badge badge-info">{{gitem.supervision_class}}</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 热门推荐 -->
		<!-- <div class="block20"></div>
		<div class="xc_goodslist_box">
			<div class="type_box">
				<div class="line" style="background-color: #FFC327;"></div>
				<div class="text">热门推荐</div>
			</div>
			<div class="datalist">
				<div class="item pointer" v-for="(item, index) in 8" :key="index" @click="clickgoodsDetail(item.id)">
					<div class="img"><img src="" /></div>
					<div class="conbox">
						<div class="title clamp2">999感冒灵白云山谷出品，精品药业999感冒灵白云山谷白云山谷</div>
						<div class="block20"></div>
						<div class="pricebox">
							<div class="nowprice">￥88</div>
							<div class="oldprice">¥188</div>
							<div class="volume">月销8099笔</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<!-- 注册采购商弹窗 -->
		<registerForm :isShow="dialogFormRegister" @handlezhuceiForm="handlezhuceiForm"></registerForm>
		<!-- 登录页面 -->
		<loginForm :isShow="isShowLoginForm" @handleLoginForm="handleLoginForm" @openzhuce="openzhuce"></loginForm>
		
		<div class="block60"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
//导入swiper
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
// 引入跑马灯组件
import  marquee  from "../components/marquee/marquee.vue";
import  topBar  from "../components/topBar/topBar.vue";
import  loginForm  from "../components/loginForm/loginForm.vue";
import  registerForm  from "../components/registerForm/registerForm.vue";
export default {
	components: {
	    swiper,
	    swiperSlide,
		marquee,
		topBar,
		loginForm,
		registerForm
	},
	data() {
		return {
			bannerList: [],
			menuList:[],//菜单栏目
			noticeList: [], //资讯列表
			swiperOptions1:{
				loop:true,
				autoplay: {
				    delay: 3000, //自动切换的时间间隔，单位ms
				},
			},
			bannerList1: [],
			swiperOptions:{
				slidesPerView: 3,
			},
			tejiaList:[],
			swiperList1:[],
			swiperList2:[],
			brandList:[],//品牌
			actList:[],//活动专区
			actMenu:[],//活动栏目
			formLabelWidth: '120px',
			dialogFormRegister:false,//采购认证
			isShowLoginForm:false,//登录操作
			loginright:"0px",
			supervision_class: ['乙类OTC', '甲类OTC', 'RX处方药', '一类器械', '二类器械', '三类器械', '非监管器械', '保健品', '食品', '其他']
		};
	},
	mounted: function() {
		//console.log(document.body.clientWidth)
		if(document.body.clientWidth>1669){
			this.loginright = "-200px"
		}
		this.initPage()
	},
	computed: {
		...mapState(['userInfo', 'isLogin','bsnName','avatarUrl','nickName']),
	},
	methods: {
		//初始化
		
		async initPage(){
			//轮播图和广告
			this.getsupervision()
			await this.getwheelPage(13)
			await this.getwheelPage(14)
			await this.getwheelPage(15)
			await this.getwheelPage(16)
			await this.getwheelPage(17)
			
			this.getSecondPage()
			this.getarticlePage()
			this.getSpecialPage()
			this.getBrandPage()
			this.getActivityPage()
		},
		//登录操作
		handleLoginForm(type){
			if(type=="open"){
				this.isShowLoginForm = true
			}else{
				this.isShowLoginForm = false
			}
		},
		openzhuce(){
			this.isShowLoginForm = false
			this.dialogFormRegister = true
		},
		//打开注册认证
		handlezhuceiForm(type){
			if(type=="open"){
				this.dialogFormRegister = true
			}else{
				this.dialogFormRegister = false
			}
		},
		getsupervision() {
			var _this = this;
			var params
			this.$mmapi.get('drugs/getSupervisionClass/getPage', params).then(function(res) {
				//数据处理
				if(res.code==200){
					_this.supervision_class=res.data;
				}
			})
		},
		//获取轮播图
		getwheelPage(type) {
			var _this = this
			return new Promise((resolve, reject) => {
				var params = {}
				params["currentPage"] = 1
				params["pageSize"] = 8
				params["type"] = type
				this.$http.post('AdvertModule/wheel-advert/getPage', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records
						if(records.length>0){
							if(type==13){
								_this.bannerList = records
							}else if(type==14){
								_this.bannerList1 = records
							}else if(type==15){
								_this.swiperList1 = records
							}else if(type==16){
								_this.swiperList2 = records
							}else if(type==17){
								_this.actList = records
							}else{}
						}
						resolve(true)
					} else {
						reject(false)
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			})
		},
		//上一页
		slidePrev() {
		    this.$refs.mySwiper.swiper.slidePrev();
		},
		//下一页
		slideNext() {
		    this.$refs.mySwiper.swiper.slideNext()
		},
		//获取商品二级类型
		getSecondPage() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 88,
			};
			this.$http.post('medicine/medicineDrugsType/getSecondPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						// records.forEach((item,index)=>{
							
						// })
						_this.menuList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取系统公告
		getarticlePage() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 8,
				moduleId: 1,//1:系统公告,2:资讯文章,3:关于我们
			};
			this.$http.post('articleModule/article-info/getSecondPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.noticeList = records
					}
				}
			})
		},
		//获取特价精选商品
		getSpecialPage() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 18,
				hot:1,//精选推荐 0否 1是
			};
			this.$http.post('medicine/medicineDrugsSpecial/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						records.forEach((item,index)=>{
							item["finalPrice"] = item.price
							item["supervision_class"] = _this.supervision_class[item.medicineDrugs.typeId]
						})
						_this.tejiaList = records
					}
				}
			})
		},
		//获取品牌
		getBrandPage() {
			var _this = this
			var params = {}
			params["currentPage"] = 1
			params["pageSize"] = 18
			this.$http.post('medicine/medicineDrugsBrand/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.brandList = records
					}
				}
			})
		},
		//获取活动栏目
		getActivityPage() {
			var _this = this
			var params = {}
			params["currentPage"] = 1
			params["pageSize"] = 15
			this.$http.post('medicine/medicineDrugsActivity/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.actMenu = records
						for(var i=0;i<_this.actMenu.length;i++){
							_this.getgoodsList(i)
						}
					}
					//console.log(_this.actMenu)
				}
			})
		},
		//商品列表
		getgoodsList(index) {
			var _this = this
			var params = {}
			params["currentPage"] = 1
			params["pageSize"] = 20
			params["activityId"] = this.actMenu[index].id
			this.$http.post('medicine/medicineDrugsSku/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					//_this.$nextTick(function(){_this.actMenu[index]["goodsList"] = records })
					records.forEach((item, index) => {
						// if(item.finalPrice){
						// 	//分转元
						// 	item["finalPrice"] = (Number(item.price)/100).toFixed(2)
						// 	item["oriPrice"] = (Number(item.oriPrice)/100).toFixed(1)
						// }
						
						//item["goodsName"] = _this.join(item.goodsName, _this.keyword)
						item["supervision_class"] = _this.supervision_class[item.medicineDrugs.typeId]
					})
					_this.actMenu[index]["goodsList"] = records
					
					_this.$forceUpdate()
				}
			})
		},
		//商品详情
		clickgoodsDetail(uid,type){
			this.$router.push({ path: '/goodsDetail', query: {uid: uid,type:type }})
		},
		//跳转文章详情
		clickarticleDetail(uid){
			this.$router.push({ path: '/infoDetail', query: {uid: uid}})
		},
		//品牌专区详情
		handlebrandDetail(uid){
			this.$router.push({ path: '/category', query: {type:'brandZone',brandId: uid }})
		},
		tobackMine(){
			this.$router.push({ path: '/mine', query: {redirect: "userinfo"}})
			this.$store.commit('SET_CURPAGE', 'mine')
		},
		//菜单栏目
		handleMenu(index){
			this.$router.push({ path: '/category', query: {type:'category',typeId: this.menuList[index].pid,typeSubId: this.menuList[index].id}})
		},
		
	}
};
</script>
<style lang="scss" scoped>

</style>
